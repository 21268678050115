/* eslint-disable no-undef */
import './App.css';
import React, { useEffect, useMemo, useState } from 'react';
import os from './assets/opensea.png';
import twitter from './assets/twitter-.png';
import home from './assets/home-button.png';
import logo from './assets/Logo.png';
import title from './assets/title.png';
import gif from './assets/gif.mp4';
import { Web3Button, Web3Modal, useWeb3Modal } from '@web3modal/react';
import { mainnet, useAccount, useContractRead, useContractReads, useContractWrite, useNetwork, usePublicClient, useSwitchNetwork } from 'wagmi';
import { createPublicClient, formatEther, http, parseEther } from 'viem';

const ops = () => {
	window.open("https://opensea.io/collection/the-konfusor");
}

const tweet = () => {
	window.open("https://twitter.com/thekonfusor");
}

const homeLink = () => {
	window.open("#");
}

let ABI = [
	{
	  "inputs": [
		{
		  "internalType": "string",
		  "name": "_baseMetadataUrl",
		  "type": "string"
		}
	  ],
	  "stateMutability": "nonpayable",
	  "type": "constructor"
	},
	{
	  "inputs": [],
	  "name": "ApprovalCallerNotOwnerNorApproved",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "ApprovalQueryForNonexistentToken",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "BalanceQueryForZeroAddress",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "MintERC2309QuantityExceedsLimit",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "MintToZeroAddress",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "MintZeroQuantity",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "OwnerQueryForNonexistentToken",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "OwnershipNotInitializedForExtraData",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "TransferCallerNotOwnerNorApproved",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "TransferFromIncorrectOwner",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "TransferToNonERC721ReceiverImplementer",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "TransferToZeroAddress",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "URIQueryForNonexistentToken",
	  "type": "error"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "owner",
		  "type": "address"
		},
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "approved",
		  "type": "address"
		},
		{
		  "indexed": true,
		  "internalType": "uint256",
		  "name": "tokenId",
		  "type": "uint256"
		}
	  ],
	  "name": "Approval",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "owner",
		  "type": "address"
		},
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "operator",
		  "type": "address"
		},
		{
		  "indexed": false,
		  "internalType": "bool",
		  "name": "approved",
		  "type": "bool"
		}
	  ],
	  "name": "ApprovalForAll",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": true,
		  "internalType": "uint256",
		  "name": "fromTokenId",
		  "type": "uint256"
		},
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "toTokenId",
		  "type": "uint256"
		},
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "from",
		  "type": "address"
		},
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "to",
		  "type": "address"
		}
	  ],
	  "name": "ConsecutiveTransfer",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "previousOwner",
		  "type": "address"
		},
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "newOwner",
		  "type": "address"
		}
	  ],
	  "name": "OwnershipTransferred",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "from",
		  "type": "address"
		},
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "to",
		  "type": "address"
		},
		{
		  "indexed": true,
		  "internalType": "uint256",
		  "name": "tokenId",
		  "type": "uint256"
		}
	  ],
	  "name": "Transfer",
	  "type": "event"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "to",
		  "type": "address"
		},
		{
		  "internalType": "uint256",
		  "name": "mintAmount",
		  "type": "uint256"
		}
	  ],
	  "name": "airdrop",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "to",
		  "type": "address"
		},
		{
		  "internalType": "uint256",
		  "name": "tokenId",
		  "type": "uint256"
		}
	  ],
	  "name": "approve",
	  "outputs": [],
	  "stateMutability": "payable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "owner",
		  "type": "address"
		}
	  ],
	  "name": "balanceOf",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "cost",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "freeAmount",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "tokenId",
		  "type": "uint256"
		}
	  ],
	  "name": "getApproved",
	  "outputs": [
		{
		  "internalType": "address",
		  "name": "",
		  "type": "address"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "hiddenMetadataUrl",
	  "outputs": [
		{
		  "internalType": "string",
		  "name": "",
		  "type": "string"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "owner",
		  "type": "address"
		},
		{
		  "internalType": "address",
		  "name": "operator",
		  "type": "address"
		}
	  ],
	  "name": "isApprovedForAll",
	  "outputs": [
		{
		  "internalType": "bool",
		  "name": "",
		  "type": "bool"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "isRevealed",
	  "outputs": [
		{
		  "internalType": "bool",
		  "name": "",
		  "type": "bool"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "maxFreeSupply",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "maxPerWallet",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "maxSupply",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "mintAmount",
		  "type": "uint256"
		}
	  ],
	  "name": "mint",
	  "outputs": [],
	  "stateMutability": "payable",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "name",
	  "outputs": [
		{
		  "internalType": "string",
		  "name": "",
		  "type": "string"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "owner",
	  "outputs": [
		{
		  "internalType": "address",
		  "name": "",
		  "type": "address"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "tokenId",
		  "type": "uint256"
		}
	  ],
	  "name": "ownerOf",
	  "outputs": [
		{
		  "internalType": "address",
		  "name": "",
		  "type": "address"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "pause",
	  "outputs": [
		{
		  "internalType": "bool",
		  "name": "",
		  "type": "bool"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "renounceOwnership",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "bool",
		  "name": "_state",
		  "type": "bool"
		}
	  ],
	  "name": "reveal",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "from",
		  "type": "address"
		},
		{
		  "internalType": "address",
		  "name": "to",
		  "type": "address"
		},
		{
		  "internalType": "uint256",
		  "name": "tokenId",
		  "type": "uint256"
		}
	  ],
	  "name": "safeTransferFrom",
	  "outputs": [],
	  "stateMutability": "payable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "from",
		  "type": "address"
		},
		{
		  "internalType": "address",
		  "name": "to",
		  "type": "address"
		},
		{
		  "internalType": "uint256",
		  "name": "tokenId",
		  "type": "uint256"
		},
		{
		  "internalType": "bytes",
		  "name": "_data",
		  "type": "bytes"
		}
	  ],
	  "name": "safeTransferFrom",
	  "outputs": [],
	  "stateMutability": "payable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "operator",
		  "type": "address"
		},
		{
		  "internalType": "bool",
		  "name": "approved",
		  "type": "bool"
		}
	  ],
	  "name": "setApprovalForAll",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "string",
		  "name": "_baseURL",
		  "type": "string"
		}
	  ],
	  "name": "setBaseUri",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "_newCost",
		  "type": "uint256"
		}
	  ],
	  "name": "setCost",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "_newAmt",
		  "type": "uint256"
		}
	  ],
	  "name": "setFreeAmount",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "newMaxFreeSupply",
		  "type": "uint256"
		}
	  ],
	  "name": "setMaxFreeSupply",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "_newAmt",
		  "type": "uint256"
		}
	  ],
	  "name": "setMaxPerWallet",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "newMaxSupply",
		  "type": "uint256"
		}
	  ],
	  "name": "setMaxSupply",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "bool",
		  "name": "_state",
		  "type": "bool"
		}
	  ],
	  "name": "setPause",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "string",
		  "name": "_hiddenMetadataUrl",
		  "type": "string"
		}
	  ],
	  "name": "sethiddenMetadataUrl",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "bytes4",
		  "name": "interfaceId",
		  "type": "bytes4"
		}
	  ],
	  "name": "supportsInterface",
	  "outputs": [
		{
		  "internalType": "bool",
		  "name": "",
		  "type": "bool"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "symbol",
	  "outputs": [
		{
		  "internalType": "string",
		  "name": "",
		  "type": "string"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "tokenId",
		  "type": "uint256"
		}
	  ],
	  "name": "tokenURI",
	  "outputs": [
		{
		  "internalType": "string",
		  "name": "",
		  "type": "string"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "totalSupply",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "from",
		  "type": "address"
		},
		{
		  "internalType": "address",
		  "name": "to",
		  "type": "address"
		},
		{
		  "internalType": "uint256",
		  "name": "tokenId",
		  "type": "uint256"
		}
	  ],
	  "name": "transferFrom",
	  "outputs": [],
	  "stateMutability": "payable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "newOwner",
		  "type": "address"
		}
	  ],
	  "name": "transferOwnership",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "",
		  "type": "address"
		}
	  ],
	  "name": "userBalance",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "withdraw",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	}
];

let address = "0xa4A575CdBf39F2E375535Eda0d0831B412D0ac36";

const maxSupply = 4444

const Home = () => {

	const { open } = useWeb3Modal()
	const { chain } = useNetwork()
	const { switchNetwork } = useSwitchNetwork()

	const { address: walletAddress } = useAccount({
		async onConnect() {
			handleConnect()
		}
	})

	const publicClient = createPublicClient({
		chain: mainnet,
		transport: http()
	})

	const [_totalSupply, settotalSupply] = useState("")
	const [statusError, setstatusError] = useState("")
	const [statusLoading, setstatusLoading] = useState("")
	const [success, setsuccess] = useState("")
	const [nftMintingAmount, setnftMintingAmount] = useState(2)
	const [_publicMintMsg, set_publicMintMsg] = useState("Mint Here")
	const [_cost, set_publicSaleCost] = useState("")
	const [_max_per_wallet, set_max_per_wallet] = useState(20)
	const [_owner, set_owner] = useState("")
	const [myNFTWallet, setmyNFTWallet] = useState("")
	const [_pause, set_pause] = useState(false)
	const [_MAX_SUPPLY, set_MAX_SUPPLY] = useState("")
	const [_maxFreeSupply, set_maxFreeSupply] = useState("")
	const [_freeAmount, set_freeAmount] = useState("")
	const [_userBalance, set_userBalance] = useState("")


	const contract = {
		address: address,
		abi: ABI
	}

	async function handleConnect() {
		if (chain.id !== 1) {
			switchNetwork(1)
		}
		var data = await getBalance()
		setmyNFTWallet(data.data)
		console.log("myNFTWallet :" + data.data)
	}

	function onPlus() {

		console.log("_pause :" + _pause);

		var nftIhave = BigInt(nftMintingAmount) + BigInt(myNFTWallet);
		console.log("nftIhave : " + nftIhave);

		if (!_pause) {

			if (nftIhave < _max_per_wallet) {

				setnftMintingAmount(nftMintingAmount + 1);

			}
		}

	}

	function onMinus() {

		console.log("1");
		var testM;
		testM = nftMintingAmount - 1;
		console.log("2");

		setnftMintingAmount(testM)
		console.log("3");

		if (testM < 2) {
			console.log("4");

			console.log("myNFTWallet : " + myNFTWallet);
			if (myNFTWallet == 0) {
				console.log("5");

				setnftMintingAmount(2);
				console.log("6");

			} else if (testM < 1) {
				console.log("7");

				setnftMintingAmount(1);
				console.log("8");

			}
			console.log("9");

		}

		console.log("10");

		console.log("testM : " + testM);

		/*if (nftMintingAmount != 1) {
			console.log("testM : " + testM);
			setnftMintingAmount(nftMintingAmount - 1)

			if (myNFTWallet === 0 && nftMintingAmount < 3) {
				setnftMintingAmount(nftMintingAmount + 1)
			}
		}*/



	}

	const { data, refetch, isSuccess } = useContractReads({
		contracts: [
			{ ...contract, functionName: 'totalSupply' },
			{ ...contract, functionName: 'maxPerWallet' },
			{ ...contract, functionName: 'owner' },
			{ ...contract, functionName: 'cost' },
			{ ...contract, functionName: 'maxSupply' },
			{ ...contract, functionName: 'pause' },
			{ ...contract, functionName: 'maxFreeSupply' },
			{ ...contract, functionName: 'freeAmount' },
			{ ...contract, functionName: 'userBalance', args: [walletAddress ? walletAddress : '0x'] }



		]
	},)

	useMemo(() => {
		if (isSuccess === true) {
			settotalSupply(Number(data[0].result))
			set_max_per_wallet(data[1].result)
			set_owner(data[2].result)
			set_publicSaleCost(Number(data[3].result) / 10 ** 18)
			set_MAX_SUPPLY(data[4].result)
			set_pause(data[5].result)
			set_maxFreeSupply(data[6].result)
			set_freeAmount(data[7].result)
			set_userBalance(data[8].result)
			console.log("pause+ : " + _pause);
		}
	}, [isSuccess])


	useEffect(() => {
		if (myNFTWallet === 0) {
			setnftMintingAmount(2)
		}
	}, [myNFTWallet])

	const { refetch: getBalance } = useContractRead({
		...contract,
		functionName: 'balanceOf',
		args: [walletAddress ? walletAddress : '0x']
	})

	console.log(data)

	const { writeAsync } = useContractWrite({
		...contract,
		functionName: 'mint',
		onError(error) {
			if (error.message.includes('balance')) {
				setstatusError(true)
				setstatusLoading(false)
			}
		}
	})

	async function onMint() {

		var mintCost;

			if (!_pause) {
				mintCost = parseFloat(_cost)
			}

			console.log("_userBalance : " + _userBalance);
			console.log("nftMintingAmount : " + nftMintingAmount);

		if ((_userBalance) < 2) {
			mintCost = ((nftMintingAmount - (Number(_freeAmount) - Number(_userBalance))) * mintCost).toString();
			console.log("mintCost1 : " + mintCost);
		} else {
			//mintCost = (mintCost * nftMintingAmount).toString()
			mintCost = (mintCost * nftMintingAmount).toString()
			console.log("mintCost2 : " + mintCost);

		}


		try {
			setstatusLoading(true)
			setstatusError(false)

			var res = await writeAsync({
				args: [nftMintingAmount],
				value: parseEther(mintCost)
			})
			var result = await publicClient.waitForTransactionReceipt(res)
			if (result.status === 'success') {
				setstatusError(false)
				setsuccess(true)
				setstatusLoading(false)
			}
			else {
				setsuccess(false)
				setstatusError(true)
				setstatusLoading(false)
			}
			await new Promise(resolve => setTimeout(resolve, 5000));
			window.location.reload(true);
		}
		catch (e) {
			console.log(e)
			setstatusError(true)
			setstatusLoading(false)
		}
	}

	return (
		<div class="allWrap">
			<div class="light">
				<div class="cont">

					<div class="headers">

						<div class="headers2">

							<div class="logo"><img class="logoPic" src={logo} /></div>

							<div class="right">

								<div class="icons">
									<div class="socialIcon"><img onClick={ops} src={os} /></div>
									<div class="socialIcon"><img onClick={tweet} src={twitter} /></div>
								</div>

								<div class="connect2">
									<Web3Button />
								</div>

							</div>

						</div>

					</div>

					<div class="introduction">

						<div class="in2">
							<div class="intro">
								<img src={title} />
							</div>

							<div class="intro2">
							"The Konfusor": 4444 unique NFTs, boundless creativity. Explore a limitless world of digital imagination. NFT enthusiasts' treasure trove, a testament to blockchain art's evolution.</div>

							<div class="nftblockWalletConnectedALL">
								{walletAddress === undefined ?
									<div class="walletConnect">

										<button onClick={() => { open() }} class="wallet3" >MINT NOW</button>

									</div>
									:
									null}
							</div>
						</div>

						{walletAddress === undefined ?

							(<div class="nftPicDiv">
								<video class="nftPic" src={gif} alt='gif' autoPlay loop playsInline muted />
							</div>) : (

								(<div class="mintDiv">
									<div class="totalSupply">{_totalSupply} / 4444</div>
									{_max_per_wallet === myNFTWallet ?

										(<div class="price"><div>Limit Reached!</div></div>) :
										(<div class="price"><div>Mint Price 0.002 ETH</div></div>)}

									<div class="minting_count_button">

										<div class="center">

											<button onClick={onMinus} class="btnfos-0-2" type="submit">-</button>

										</div>

										<div>
											<div class="nftminter2">{nftMintingAmount}</div>
										</div>


										<div class="center">
											<button onClick={onPlus} class="btnfos-0-2" type="submit">+</button>

										</div>
									</div>

									<div class="mintbuttondiv">
										{/* <form onSubmit={this.onSubmit2}> */}
										<button class="btnfos-0-3" onClick={onMint}>
											{_publicMintMsg}</button>
										{/* </form> */}

									</div>
									<div>

										{statusError ? (
											<div class="errorMessage">
												<div >Sorry, something went wrong <br /> please try again later</div>
											</div>)
											: null}

										{statusLoading ? (
											<div class="loadingContainer">
												<div class="loadingText">Minting your NFT</div>
											</div>)
											: null}

										{success ? (
											<div class="successfully">MINTING SUCCESSFUL!</div>
										)
											: null}

									</div></div>

								)
							)}
					</div>
				</div>
			</div >
		</div >
	)

}
export default Home;
